<template>
  <v-card
    id="define-map"
    flat
    min-height="550"
    min-width="100%"
    max-height="900"
    max-width="100%"
  >
    <v-card
      elevation="8"
      min-height="500"
      min-width="100%"
      max-height="800"
      max-width="100%"
    >
      <v-overlay :value="global_layers.overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <div
        id="map"
        class="map"
      />
    </v-card>

    <v-card-actions class="card-action flex-end justify-space-between">
      <div class="d-flex flex-row flex-end">
        <v-color-picker
          v-model="color"
          dot-size="19"
          mode="hexa"
          hide-inputs
          hide-mode-switch
          min-width="200"
          max-width="500"
          canvas-height="100"
          class="mr-4"
          @input="setColorWidth"
        />
        <v-select
          v-model="width"
          class="select"
          :items="widths"
          :label="$t('CustomerLayer.width')"
          outlined
          hide-details
          @change="setColorWidth"
        />
      </div>
      <div class="containerButtons">
        <v-btn
          dark
          color="deep-orange accent-3"
          @click="clearMap"
        >
          {{ $t('CustomerLayer.clear') }}
        </v-btn>
        <v-btn
          color="primary"
          class="ml-4"
          :disabled="checkForm()"
          @click="getAllData"
        >
          {{ $t('CustomerLayer.confirm') }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import 'ol/ol.css';
import { S3_BUCKETS } from '@/constants.js';
import { mapState, mapActions } from 'vuex';
import { loadLanguageAsync } from '@/plugins/i18n.js';
import Overlay from 'ol/Overlay';

export default {
  props: {
    layerForm: {
      type: Object,
      default: null
    },
    layerPolygon: {
      type: File,
      default: null
    }
  },
  data() {
    return {
      overlay: false,
      pathS3: null,
      widths: [1, 2, 3, 4, 5],
      color: '#FF0000',
      width: 1
    };
  },
  computed: {
    ...mapState(['global_layers', 'file_transfer', 'app_user', 'map'])
  },
  async mounted() {
    await this.initiateMap();

    if (this.global_layers.isEditing) {
      this.color = this.layerForm.color;
      this.width = this.layerForm.width;
    }
  },
  methods: {
    ...mapActions([
      'createGlobalLayerAction',
      'overlayAction',
      'setUpload',
      'editGlobalLayerAction',
      'setUpKML',
      'deleteKML',
      'saveDataLayer',
      'startMap',
      'setLayerGeojson',
      'getGEOJSON',
      'getKML',
      'getSHP',
      'storeCustomerLayers',
      'setStylesArray'
    ]),
    async initiateMap() {
      await this.startMap();

      if (!this.global_layers.isEditing || this.global_layers.isChangingFile) {
        if (this.layerPolygon.name.includes('.geojson')) {
          await this.setLayerGeojson([this.color, this.width]);
        }

        if (this.layerPolygon.name.includes('.kml') || this.layerPolygon.name.includes('.zip')) {
          const params = {
            Bucket: S3_BUCKETS.global_layer(),
            Key: `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`,
            Body: this.layerPolygon
          };
          this.pathS3 = `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`;
          const item = {
            Bucket: S3_BUCKETS.global_layer(),
            Key: `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`
          };
          const data = [params, item, this.layerPolygon.name, this.color, this.width];
          this.layerForm.layer_polygon = `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`;
          await this.setUpKML(data);
        }
      } else {
        const layer_obj = {
          Bucket: S3_BUCKETS.global_layer(),
          Key: `${this.layerForm.layer_polygon}`
        };
        const data = [layer_obj, this.layerForm.color, this.layerForm.width];

        if (this.layerForm.layer_type === 'geojson') {
          this.global_layers.fileChoice = 'geojson';
          await this.getGEOJSON(data);
        }

        if (this.layerForm.layer_type === 'kml') {
          this.global_layers.fileChoice = 'kml';
          await this.getKML(data);
        }

        if (this.layerForm.layer_type === 'shp') {
          this.global_layers.fileChoice = 'shp';
          await this.getSHP(data);
        }
      }

      this.setGlobalLayersInteractions();
    },
    setGlobalLayersInteractions() {
      const overlay = new Overlay({
        positioning: 'center-left',
        offset: [10, 0],
        element: document.createElement('div')
      });
      this.global_layers.map.addOverlay(overlay);

      this.global_layers.map.on('pointermove', event => {
        let featureDetected = false;
        this.global_layers.map.forEachFeatureAtPixel(event.pixel, feature => {
          if (feature) {
            featureDetected = true;
            const properties = feature.getProperties();
            let isFirstProperty = true;
            const props = Object.keys(properties).map((item) => {
              if (item !== 'geometry') {
                const result = (isFirstProperty ? '' : '<br />') + `<span><b>${item}:</b> ${properties[item]}</span>`;
                isFirstProperty = false;

                return result;
              }

              return '';
            });
            overlay.setPosition(feature.getGeometry().flatCoordinates);
            overlay.getElement().innerHTML = `
              <style>
                .overlay-style {
                  color: white;
                  font: 12px Open Sans;
                  padding: 5px 10px;
                  background-color: rgba(0, 0, 0, 0.7);
                  border: 1px solid green;
                  border-radius: 5px;
                  z-index: 100;
                }
              </style>
              <div class="overlay-style">${props.join('')}</div>`;
            overlay.getElement().style.display = 'block';
          }
        });

        if (!featureDetected) {
          overlay.getElement().style.display = 'none';
        }
      });
    },
    async clearMap() {
      this.global_layers.map = null;
      this.global_layers.renderFileMap = false;
      this.emitEvent();
    },
    emitEvent() {
      this.$emit('closeMap');
    },
    checkForm() {
      return this.layerForm.layer_name === '';
    },
    async getAllData() {
      if (!this.global_layers.isEditing) {
        if (this.layerPolygon.name.includes('.geojson')) {
          const params = {
            Bucket: S3_BUCKETS.global_layer(),
            Key: `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`,
            Body: this.layerPolygon
          };

          this.layerForm.layer_polygon = `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`;
          await this.setUpload(params);
        }

        this.overlay = true;
        this.overlayAction(this.overlay);
        let type = '';

        if (this.layerPolygon.name.includes('.kml')) {
          type = 'kml';
        } else if (this.layerPolygon.name.includes('.geojson')) {
          type = 'geojson';
        } else {
          type = 'shp';
        }

        this.layerForm.color = this.color;
        this.layerForm.width = this.width;
        this.layerForm.layer_type = type;
        this.layerForm.crm_id = this.app_user.crm_id || '';

        await this.createGlobalLayerAction(this.layerForm);

        this.overlay = false;
        this.overlayAction(this.overlay);
      } else {
        if (this.layerPolygon != null && this.layerPolygon.name.includes('.geojson')) {
          const params = {
            Bucket: S3_BUCKETS.global_layer(),
            Key: `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`,
            Body: this.layerPolygon
          };

          this.layerForm.layer_polygon = `${this.layerForm.global_layer_id}/${this.layerPolygon.name}`;
          await this.setUpload(params);
        }

        this.overlay = true;
        this.overlayAction(this.overlay);
        let type = '';

        if (this.layerPolygon != null && this.global_layers.isChangingFile) {
          if (this.layerPolygon.name.includes('.kml')) {
            type = 'kml';
          } else if (this.layerPolygon.name.includes('.geojson')) {
            type = 'geojson';
          } else {
            type = 'shp';
          }

          this.layerForm.layer_type = type;
        }

        this.layerForm.color = this.color;
        this.layerForm.width = this.width;

        await this.editGlobalLayerAction(this.layerForm);

        this.overlay = false;
        this.overlayAction(this.overlay);
        this.global_layers.isChangingFile = false;
      }

      this.storeCustomerLayers(this.app_user.crm_id);

      this.emitEvent();
      const language = this.app_user.app_user_data[0].language;
      await loadLanguageAsync(language.toLowerCase());
      this.map.mapLocale = language.toLowerCase() === 'it' ? 'Italian' : 'English';
      this.map.MBmap.setLanguage(this.map.mapLocale);
    },
    setColorWidth() {
      this.setStylesArray([this.color, this.width]);
      this.global_layers.map.getLayers().getArray()[1].setStyle(this.global_layers.styles);
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  position: absolute;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
}
.card-action {
  margin-top: 5px;
}
.margin-button {
  margin-bottom: 30px;
}
.select {
  min-width: 100px;
  max-width: 300px;
}
.flex-end {
  align-items: flex-end;
  flex-wrap: wrap;
}

.containerButtons {
  width: -webkit--fill-available;
  margin-top: 32px;
}
</style>
